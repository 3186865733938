<template>
  <v-app id="inspire">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="notify" timeout="2000" top>
      {{ notification }}
    </v-snackbar>
    <v-dialog
      v-model="showProfile"
      max-width="1000px"
      scrollable
      :fullscreen="mobileView"
    >
      <profile ref="profile" @submit="close" @update="updateAvatar" />
    </v-dialog>
    <v-dialog v-model="confirm" width="300">
      <v-card>
        <v-card-title />
        <v-card-text>
          {{ $t("dashboard.forOthersConfirm.title") }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="addNew">
            {{ $t("dashboard.forOthersConfirm.own") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addMultiple">
            {{ $t("dashboard.forOthersConfirm.others") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <reward-solicitation
      :open="askReward"
      @update:open="(value) => (askReward = value)"
    />
    <record-fish ref="recordFish" @done="askForRewardConfig" />
    <juvenile-billfish-form
      :open="openJuvenile"
      @update:open="(value) => (openJuvenile = value)"
      set-user
    />
    <reward-solicitation ref="rewardForm" />
    <batch-review ref="batchReview" />
    <tutorial ref="tutorial" />
    <v-dialog v-model="askTutorial" :width="mobileView ? '300' : '600'">
      <v-card>
        <v-card-title />
        <v-card-text>
          Welcome to the new Billfish Tag & Release Program Application. For
          your convenience here there are a few short video tutorials to help
          you with the basic actions in the new application
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="5">
              <v-btn color="primary" @click="openTutorial" block>
                See video tutorials
              </v-btn>
            </v-col>
            <v-col cols="12" md="7">
              <v-row>
                <v-col>
                  <v-btn color="primary" text @click="remindLater" block>
                    {{ mobileView ? "Later" : "Remind me later" }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn color="primary" text @click="dismissTutorial" block>
                    Dismiss
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FAQs :open="openFAQs" @close="openFAQs = false" />

    <HeaderSection
      ref="header"
      v-model="expandOnHover"
      @profile="openProfile"
      @tutorial="$refs.tutorial && $refs.tutorial.show()"
    />

    <v-main>
      <v-banner
        single-line
        color="primary white--text"
        class="banner"
        v-if="!!winners"
      >
        <div class="marquee pt-1" id="text_marquee">
          <span>Congratulations! The last year you won</span>
          <span v-for="(win, i) in winners" :key="i" class="ml-1">
            <v-icon slot="icon" color="yellow darken-1">
              mdi-alert-decagram
            </v-icon>
            {{ win }}
          </span>
        </div>
      </v-banner>
      <marquee-image />
      <router-view />
    </v-main>

    <Sidebar
      ref="sidebar"
      :expand-on-hover.sync="expandOnHover"
      @edit="openProfile"
      @tag="openDialog('Tags')"
      @release="openDialog('Releases')"
      @recapture="openDialog('Recaptures')"
      @juvenile="openDialog('Juvenile')"
      @tutorial="$refs.tutorial && $refs.tutorial.show()"
      @faqs="openFAQs = true"
    />

    <FooterSection></FooterSection>
  </v-app>
</template>

<script>
import Sidebar from "./sidebar/Sidebar";
import { mapState, mapMutations, mapActions } from "vuex";
import Profile from "@/views/Dashboard/Profile.vue";
import MarqueeImage from "../../components/appComponents/MarqueeImage.vue";
import RecordFish from "../../views/Records/RecordFish.vue";
import BatchReview from "../../views/Records/BatchReview.vue";
import {
  getTutorialInfo,
  getUserInfo,
  sendRecaptureMail,
} from "../../handlers";
import Tutorial from "../../views/Auth/Tutorial.vue";
import { differenceInHours } from "date-fns";
import FAQs from "../../views/Dashboard/FAQs.vue";
import HeaderSection from "./header/Header.vue";
import FooterSection from "./footer/Footer.vue";
import JuvenileBillfishForm from "../../views/Records/JuvenileBillfishForm.vue";
import RewardSolicitation from "../../views/Records/RewardSolicitation.vue";
export default {
  name: "FullLayout",
  components: {
    Sidebar,
    Profile,
    MarqueeImage,
    RecordFish,
    BatchReview,
    Tutorial,
    FAQs,
    HeaderSection,
    FooterSection,
    "juvenile-billfish-form": JuvenileBillfishForm,
    "reward-solicitation": RewardSolicitation,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    showProfile: false,
    notify: false,
    notification: null,
    eventType: "Tags",
    confirm: false,
    overlay: false,
    askTutorial: false,
    openFAQs: false,
    winnerData: {},
    openJuvenile: false,
    askReward: false,
  }),
  computed: {
    ...mapState(["Customizer_drawer"]),
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    winners() {
      const list = this.refactorWinnerList(this.winnerData || {});
      return list?.length ? list : null;
    },
  },
  async mounted() {
    const user = getUserInfo();
    const getWinners = await this.getWinners({ userId: user?.id });
    this.winnerData = getWinners?.data?.docs || {};

    const response = await this.getAvatar();
    const avatar =
      response?.data?.docs && JSON.parse(response.data.docs)?.image;
    this.updateAvatar(avatar);

    const tutorialData = getTutorialInfo();
    if (tutorialData?.remindMe) {
      const diffHours = differenceInHours(
        new Date(),
        new Date(tutorialData.remindMe)
      );
      diffHours >= 24 && (this.askTutorial = true);
    }
  },
  watch: {
    winners: {
      handler: "animate",
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
    ...mapActions("users", ["saveUserInfo", "getUser"]),
    ...mapActions("entry", [
      "addTag",
      "editTag",
      "addRelease",
      "editRelease",
      "addRecapture",
      "editRecapture",
      "getAvatar",
    ]),
    ...mapActions("dashboard", ["getWinners"]),
    async close(data) {
      this.showProfile = false;
      if (data) {
        const response = await this.saveUserInfo(data);
        this.notify = true;
        this.notification = response
          ? "Profile Updated"
          : "Something went wrong";
      }
    },
    openProfile() {
      this.showProfile = true;
      this.$refs.profile && this.$refs.profile.init();
    },
    updateAvatar(img) {
      this.$refs.header && this.$refs.header.updateAvatar(img);
      this.$refs.sidebar && this.$refs.sidebar.updateAvatar(img);
    },
    addNew() {
      this.confirm = false;
      setTimeout(() => {
        this.$refs.recordFish &&
          this.$refs.recordFish.init(null, this.eventType);
      }, 50);
    },
    addMultiple() {
      this.confirm = false;
      this.$refs.batchReview && this.$refs.batchReview.init(this.eventType);
    },
    async mailRecapture() {
      await sendRecaptureMail();
    },
    async openDialog(type) {
      this.overlay = true;

      if (type === "Juvenile") {
        this.openJuvenile = true;
        this.overlay = false;
        return;
      }

      this.eventType = type;

      const user = getUserInfo();
      const response = await this.getUser({ id: user.id });
      const { multipleEvents } = response?.data || {};

      if (multipleEvents) {
        this.confirm = true;
        this.overlay = false;
        return;
      }

      this.overlay = false;
      this.addNew();
    },
    openTutorial() {
      this.askTutorial = false;
      localStorage.removeItem("tutorial");
      this.$refs.tutorial && this.$refs.tutorial.show();
      // const tutorial = this.$router.resolve({ path: 'tutorial' });
      // window.open(tutorial.href, '_blank');
    },
    remindLater() {
      this.askTutorial = false;
      const remindMe = addDays(new Date(), 1);
      localStorage.setItem("tutorial", JSON.stringify({ remindMe }));
    },
    dismissTutorial() {
      this.askTutorial = false;
      localStorage.removeItem("tutorial");
    },
    animate(winners) {
      if (!winners) {
        return;
      }

      setTimeout(() => {
        const element = document.getElementById("text_marquee");

        if (!element) {
          return;
        }

        let elementWidth = element.offsetWidth;
        let parentWidth = element.parentElement.offsetWidth;
        let flag = 0;

        setInterval(() => {
          element.style.marginLeft = --flag + "px";

          if (elementWidth == -flag) {
            flag = parentWidth;
          }
        }, 10);
      }, 50);
    },
    refactorWinnerList(competitions = {}) {
      return Object.keys(competitions).map((competition) => {
        const [rawProgram, rawCategory] = competition.split("-");
        const program = rawProgram.replace(/_/gm, " ");
        const category = rawCategory.replace(/_/gm, " ");
        const place = this.getPlaces(competitions[competition]);
        return `${place} place in the ${program} as ${category}`;
      });
    },
    getPlaces(place) {
      return place > 3
        ? `${place}th`
        : place === 3
        ? "3rd"
        : place === 2
        ? "2nd"
        : "1st";
    },
    async askForRewardConfig(data) {
      const { recordType, crew, recordId } = data || {};
      if (!/recaptur/i.exec(recordType)) {
        return;
      }

      if (this.$refs.rewardForm) {
        this.overlay = true;
        await this.$refs.rewardForm.init(recordId, crew);
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
.marquee {
  font-weight: bold;
  white-space: nowrap;
  clear: both;
  float: left;
}
div[class="v-banner__wrapper"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
div[class="v-banner__content"] {
  padding-right: 0 !important;
}
</style>
