//Import application dependencies
import Vue from "vue";
import Vuetify from "vuetify";
import vuetify from "./plugins/vuetify";
import router from "./router";
import App from "./App.vue";
import Vuebar from "vuebar";
import "vuetify/dist/vuetify.min.css";
import "./plugins/base";
import { VueMaskDirective } from "v-mask";
import store from "@/stores";
import VueI18n from "vue-i18n";
import { VueReCaptcha } from "vue-recaptcha-v3";
import en from "./i18n/en";
import es from "./i18n/es";

Vue.directive("mask", VueMaskDirective);

Vue.use(Vuetify);
Vue.use(Vuebar);
Vue.use(VueI18n);

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_KEY });

const locale = localStorage.getItem("locale") || "en";
export const i18n = new VueI18n({ locale, messages: { en, es } });

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  validations: {},
  vuetify,
  i18n,
});
