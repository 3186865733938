import { handleCall } from "@/handlers/index";

class DashboardModule {
  constructor(httpClientFactory) {
    const entryAPI = httpClientFactory.clients.entries;

    this._store = {
      state: {
        data: {
          tags: null,
          releases: null,
          recaptures: null,
          trophies: null,
        },
      },
      actions: {
        getTag({ commit }, params) {
          const path = `tags/${params?.id}`;
          return handleCall("GET", entryAPI, path);
        },
        getRelease({ commit }, params) {
          const path = `releases/${params?.id}`;
          return handleCall("GET", entryAPI, path);
        },
        getRecapture({ commit }, params) {
          const path = `recaptures/${params?.id}`;
          return handleCall("GET", entryAPI, path);
        },
        getTags({ commit }, params) {
          const { legacyId, page, limit, sort, order, filter } = params;
          let queryParams = `userId=${legacyId}&page=${page}&limit=${limit}&filter=${JSON.stringify(
            filter
          )}`;
          sort && (queryParams = queryParams.concat(`&sorting=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          const path = `tags?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getReleases({ commit }, params) {
          const { legacyId, page, limit, sort, order, filter } = params;
          let queryParams = `userId=${legacyId}&page=${page}&limit=${limit}&filter=${JSON.stringify(
            filter
          )}`;
          sort && (queryParams = queryParams.concat(`&sorting=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          const path = `releases?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getRecaptures({ commit }, params) {
          const { legacyId, page, limit, sort, order, filter } = params;
          let queryParams = `userId=${legacyId}&page=${page}&limit=${limit}&filter=${JSON.stringify(
            filter
          )}`;
          sort && (queryParams = queryParams.concat(`&sorting=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          const path = `recaptures?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getJuveniles({ commit }, params) {
          const { legacyId, page, limit, sort, order } = params;
          let queryParams = `userId=${legacyId}&page=${page}&limit=${limit}`;
          sort && (queryParams = queryParams.concat(`&sorting=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          const path = `juvenile-billfish?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getTrophies({ commit }, params) {
          const queryParams = `userId=${params?.legacyId}&page=${params?.page}&limit=${params?.limit}`;
          const path = `trophies?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getItems({ states }, params) {
          let queryParams = `page=${params.page}&limit=${params.limit}`;
          params?.name &&
            (queryParams = queryParams.concat(`&name=${params?.name}`));
          const path = `${params.item}?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getCompetition({ state }, params = {}) {
          const { acronym, year } = params;
          let query = "competitions";
          (acronym || year) && (query = query.concat("?"));
          acronym && (query = query.concat(`acronym=${acronym}`));
          acronym && year && (query = query.concat("&"));
          year && (query = query.concat(`year=${year}`));
          return handleCall("GET", entryAPI, query);
        },
        getCategories({ state }, params = {}) {
          const { competition, category } = params;
          let query = "competitions/categories";
          (competition || category) && (query = query.concat("?"));
          competition && (query = query.concat(`competition=${competition}`));
          competition && category && (query = query.concat("&"));
          category && (query = query.concat(`category=${category}`));
          return handleCall("GET", entryAPI, query);
        },
        getOthersTags({ commit }, params) {
          const { legacyId, page, limit, sort, order, filter } = params;
          let queryParams = `userId=${legacyId}&page=${page}&limit=${limit}&filter=${JSON.stringify(
            filter
          )}`;
          sort && (queryParams = queryParams.concat(`&sorting=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          const path = `tags/others?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getOthersReleases({ commit }, params) {
          const { legacyId, page, limit, sort, order, filter } = params;
          let queryParams = `userId=${legacyId}&page=${page}&limit=${limit}&filter=${JSON.stringify(
            filter
          )}`;
          sort && (queryParams = queryParams.concat(`&sorting=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          const path = `releases/others?${queryParams}`;
          return handleCall("GET", entryAPI, path);
        },
        getLocation({ state }, params = {}) {
          const { page, limit, ...body } = params;
          const queryParams = `page=${page}&limit=${limit}`;
          const query = `getLocations?${queryParams}`;
          return handleCall("POST", entryAPI, query, body);
        },
        getStatistics({ state }) {
          return handleCall("GET", entryAPI, `statistics`);
        },
        getWinners({ state }, params = {}) {
          const query = `competitions/winners/${params?.userId}`;
          return handleCall("GET", entryAPI, query);
        },
        async getFilterResults({ state }, params) {
          let queryParams = `event=${String(params.event).toLowerCase()}`;

          !!params.page &&
            (queryParams = queryParams.concat(`&page=${params.page}`));
          !!params.limit &&
            (queryParams = queryParams.concat(`&limit=${params.limit}`));
          !!params.order &&
            (queryParams = queryParams.concat(`&order=${params.order}`));
          !!params.sort &&
            (queryParams = queryParams.concat(`&sorting=${params.sort}`));

          !!params.species?.id &&
            (queryParams = queryParams.concat(
              `&specie=${params.species.name}`
            ));
          !!params.ocean &&
            (queryParams = queryParams.concat(`&ocean=${params.ocean}`));

          // const nullValue =
          //   Object.values(params.quadrant.NE.latitude).some((v) => !v) &&
          //   Object.values(params.quadrant.NE.longitude).some((v) => !v) &&
          //   Object.values(params.quadrant.SW.latitude).some((v) => !v) &&
          //   Object.values(params.quadrant.SW.longitude).some((v) => !v);
          // !nullValue &&
          //   (queryParams = queryParams.concat(
          //     `&quadrant=${JSON.stringify(params.quadrant)}`
          //   ));
          !!params.tag &&
            (queryParams = queryParams.concat(`&tagNumber=${params.tag}`));
          !!params.priorTag &&
            (queryParams = queryParams.concat(`&priorTag=${params.priorTag}`));

          !!params.user?._id &&
            (queryParams = queryParams.concat(`&user=${params.user._id}`));
          !!params.role &&
            (queryParams = queryParams.concat(`&role=${params.role}`));

          !!params.boat?.name &&
            (queryParams = queryParams.concat(`&boat=${params.boat?.name}`));
          !!params.location &&
            (queryParams = queryParams.concat(`&location=${params.location}`));
          !!params.bait &&
            (queryParams = queryParams.concat(`&bait=${params.bait}`));
          !!params.hook &&
            (queryParams = queryParams.concat(`&hook=${params.hook}`));
          !!params.export &&
            (queryParams = queryParams.concat(`&export=${params.export}`));
          !!params.format &&
            (queryParams = queryParams.concat(`&format=${params.format}`));

          params.catchDate &&
            params.catchDate[0] &&
            params.catchDate[1] &&
            (queryParams = queryParams.concat(
              `&catchDate=${params.catchDate}`
            ));
          !!params.enteredBy &&
            (queryParams = queryParams.concat(
              `&enteredBy=${params.enteredBy}`
            ));
          params.enteredByDate &&
            params.enteredByDate[0] &&
            params.enteredByDate[1] &&
            (queryParams = queryParams.concat(
              `&enteredByDate=${params.enteredByDate}`
            ));
          return handleCall("GET", entryAPI, `/limited-search?${queryParams}`);
        },
      },
      getters: {
        tags(state) {
          return state?.data?.tags;
        },
      },
      mutations: {
        setTags(state, tags) {
          state.data.tags = tags;
        },
        setRecaptures(state, recaptures) {
          state.data.recaptures = recaptures;
        },
        setReleases(state, releases) {
          state.data.releases = releases;
        },
        setTrophies(state, trophies) {
          state.data.trophies = trophies;
        },
      },
      namespaced: true,
    };
  }

  get store() {
    return this._store;
  }
}

export default DashboardModule;
