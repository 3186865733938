<template>
  <div>
    <v-dialog v-model="onCreateLocation" max-width="800px" :fullscreen="!full">
      <new-location
        ref="newLocation"
        @close="onCreateLocation = false"
        @location="setNewLocation"
      />
    </v-dialog>
    <v-autocomplete
      :items="docs"
      :label="label"
      :loading="loading"
      v-model="item"
      item-text="name"
      item-value="name"
      hide-details
      :search-input.sync="searchInput"
      :rules="rules"
      return-object
      no-filter
      :prepend-icon="
        edit ? 'mdi-circle-edit-outline' : add ? 'mdi-map-marker-plus' : null
      "
      @click:prepend="edit ? $emit('edit') : (onCreateLocation = true)"
      @click:clear="() => getLocationByName('', true)"
      outlined
      :dense="full"
      clearable
      attach
    >
      <template v-slot:no-data>
        <div
          class="text-caption mx-5 font-weight-medium"
          v-text="'Type to search'"
        />
      </template>
      <!-- <template v-slot:item="{ item }">
        <span v-if="!item['distance']">{{ item.name }}</span>
        <v-list-item v-else class="pl-0">
          <v-list-item-content>
            <span class="text-subtitle-2">{{ item.name }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-action>
          <span class="font-weight-thin text-caption">{{
            item.distance + "mi"
          }}</span>
        </v-list-item-action>
      </template> -->
    </v-autocomplete>
    <div v-if="hint" class="text-center">{{ $t("form.setLocationHint") }}</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NewLocation from "@/views/Records/NewLocation.vue";
import { CoordinatesViewModel } from "../../views/models";
import { validCoordinates } from "../../handlers";
export default {
  components: { NewLocation },
  name: "autocomplete-location",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: () => null,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
    coordinates: {
      type: Object,
      default: () => new CoordinatesViewModel(),
    },
    hint: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    docs: [],
    search: null,
    loading: false,
    onCreateLocation: false,
  }),
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    edit() {
      return this.editable && !!this.value;
    },
    add() {
      return this.addNew && !this.value;
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (search) {
        if (search !== this.search) {
          this.search = search;
          search ? this.getLocationByName(search) : (this.docs = []);
        }
      },
    },
    item: {
      get: function () {
        if (this.value) {
          const exist = this.docs.some((doc) => this.value === doc.name);
          !exist && this.getLocationByName(this.value);
        }
        return this.value;
      },
      set: function (item) {
        const { name, coordinates, ocean } = item || {};
        if (coordinates) {
          coordinates.description = name;
        }
        this.$emit("input", name);
        // this.$emit("locate", coordinates);
        this.$emit("ocean", ocean?.name || null);
      },
    },
  },
  methods: {
    ...mapActions("dashboard", ["getLocation"]),
    async getLocationByName(name = "", forceCoords = false) {
      this.loading = true;

      const payload = { name, page: 1, limit: 10, type: "approved" };
      const validCoords = validCoordinates(this.coordinates);
      if (validCoords && (!this.coordinates?.description || forceCoords)) {
        payload["coordinates"] = this.coordinates;
      }

      const { data } = (await this.getLocation(payload)) || {};
      this.docs = data?.docs || [];
      this.loading = false;
    },
    setNewLocation(value) {
      const { coordinates, name } = value;
      coordinates.description = name;
      this.$emit("locate", coordinates);
      this.item = value;
    },
  },
  watch: {
    coordinates: {
      handler: "getLocationByName",
    },
  },
};
</script>
