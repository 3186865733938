<template>
  <v-autocomplete
    :items="docs"
    :label="label"
    :loading="loading"
    v-model="item"
    item-value="_id"
    hide-details
    return-object
    :search-input.sync="searchInput"
    :rules="rules"
    no-filter
    :prepend-icon="edit ? 'mdi-account-edit' : null"
    @click:prepend="edit ? $emit('edit') : null"
    outlined
    :dense="full"
    clearable
    attach
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ `${item.firstName} ${item.lastName}` }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ getAddress(item) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      {{ `${item.firstName} ${item.lastName}` }}
    </template>
    <template v-slot:no-data>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("form.typeHint") }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="addNew">
            {{ $t("form.registerHint") }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="addNew">
          <v-btn icon @click="$emit('add')" :x-large="!full" :outlined="!full">
            <v-icon color="primary">mdi-account-edit</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from "vuex";
import { CrewViewModel } from "../../views/models";
export default {
  name: "autocomplete-users",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, String],
      default: () => null,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    docs: [],
    search: null,
    loading: false,
    tempName: null,
  }),
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    edit() {
      return this.editable && !!this.value?.id;
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (v) {
        if (v !== this.search) {
          this.search = v;
          v ? this.getUserList(v) : (this.docs = []);
        }
      },
    },
    item: {
      get: function () {
        const { firstName, lastName, _id, id } = this.value || {};
        const name = [firstName, lastName].filter(Boolean).join(" ") || "";
        const userId = _id || id;

        if (!name) {
          return userId;
        }

        if (this.tempName === name && this.docs.length !== 1) {
          return userId;
        }

        if (userId) {
          const exist = this.docs.some((doc) => userId === doc._id);
          !exist && this.getUserList(name);
          return userId;
        }

        if (!this.docs.length) {
          this.getUserList(name);
          this.$emit("input", this.value);
          return null;
        }

        if (this.docs.length === 1) {
          const { _id, id } = this.docs[0];
          return _id || id;
        }

        return null;
      },
      set: function (item) {
        this.$emit("input", item);
      },
    },
  },
  methods: {
    ...mapActions("users", ["getUsers"]),
    async getUserList(name = "") {
      this.tempName = name;
      this.loading = true;
      const payload = { name, page: 1, limit: 10 };
      const response = await this.getUsers(payload);
      this.docs = response?.data?.docs || [];
      this.loading = false;
    },
    getAddress(user) {
      const { line1, city, zip, state, country } = user?.address || {};
      let address = line1 || "";
      !!address.length && city && (address = address.concat(", "));
      city && (address = address.concat(city));
      !!address.length && zip && (address = address.concat(", "));
      zip && (address = address.concat(zip));
      !!address.length && state && (address = address.concat(", "));
      state && (address = address.concat(state));
      !!address.length && country && (address = address.concat(", "));
      country && (address = address.concat(country));
      !address.length && (address = "No address registered");
      return address;
    },
  },
};
</script>
