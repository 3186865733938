<template>
  <v-container fluid>
    <v-dialog v-model="onCreateBoat" max-width="400px" :fullscreen="!full">
      <new-boat ref="newBoat" @close="onCreateBoat = false" />
    </v-dialog>
    <image-uploader
      :show="showImgUploader"
      @close="showImgUploader = false"
      v-model="fishPicture"
      :newRecord="newRecord"
    />
    <v-snackbar v-model="notify" :timeout="3000" top>
      {{ message }}
    </v-snackbar>
    <form ref="coordsForm" :disabled="loading" v-if="coordsStep">
      <coordinates-form
        v-model="input.coordinates"
        ref="coordsInputs"
        @change="setMarker"
      />
      <fishing-locations
        ref="fishingLocations"
        @coords="setCoords"
        @validate="notifyInvalidCoords"
        draggable
        :input="input"
      />
    </form>
    <v-form ref="tagForm" @submit="submit" :disabled="loading" v-else>
      <v-banner
        class="text-caption banner-note"
        two-line
        color="blue lighten-5"
        v-if="isRecapture && bannerNote"
      >
        <span>{{ $t("form.warningNote") }}</span>
        <template v-slot:icon>
          <v-btn icon small @click="bannerNote = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <v-row>
        <v-col cols="12" sm="6" md="9">
          <auto-complete-location
            ref="locations"
            :label="$t('form.location')"
            v-model="input.coordinates.description"
            @ocean="(value) => (input.capture.ocean = value)"
            @locate="setMarker"
            addNew
            :rules="rule"
            :coordinates="input.coordinates"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <date-picker
            v-model="input.createdDate"
            :label="isRecapture ? $t('form.recaptureDate') : $t('form.tagDate')"
            @clear="input.createdDate = null"
            :rules="rule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3" v-if="isRecapture">
          <v-text-field
            :label="$t('form.prior')"
            :rules="rulePriorTag.concat(tagRule)"
            v-mask="'BF#######'"
            v-model="priorNumber"
            outlined
            dense
            clearable
            :hint="$t('form.recaptureHint')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            :label="tagNumberLabel"
            :rules="
              isRecapture
                ? recaptureRule.concat(noRepeatNumberRule)
                : rule.concat(tagRule)
            "
            v-model="number"
            v-mask="'BF#######'"
            outlined
            :dense="full"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <auto-complete
            :label="$t('form.ocean')"
            :rules="rule"
            itemType="oceans"
            v-model="input.capture.ocean"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <auto-complete
            :label="$t('form.species')"
            :rules="rule"
            itemType="species"
            v-model="input.capture.fish.specie"
            :ocean="input.capture.ocean"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="specieSelected">
          <v-btn
            dark
            color="primary"
            @click="showImgUploader = true"
            block
            :x-large="!full"
          >
            <v-icon left> mdi-file-image-plus </v-icon>
            {{ $t("loadImage") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="font-weight-medium">{{ $t("form.fishLength") }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="input.capture.fish.length.value"
            :label="$t('form.value')"
            v-mask="'#######'"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            :rules="lengthRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.unit"
            :label="$t('form.unit')"
            :items="length.units"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.type"
            :label="$t('form.type')"
            :items="length.types"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`length.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.determination"
            :label="$t('form.determination')"
            :items="determinations"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-medium">{{ $t("form.fishWeight") }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="input.capture.fish.weight.value"
            :label="$t('form.value')"
            v-mask="'#######'"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            :rules="weightRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.unit"
            :label="$t('form.unit')"
            :items="weight.units"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.unit.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.type"
            :label="$t('form.type')"
            :items="weight.types"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`weight.type.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.determination"
            :label="$t('form.determination')"
            :items="determinations"
            outlined
            :dense="full"
            clearable
            item-text="name"
            item-value="name"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
            <template v-slot:selection="{ item }">
              <span v-if="item.code">{{
                $i18n.locale === "es"
                  ? $t(`determination.${item.code}`, {
                      [`${item.code}`]: item.name,
                    })
                  : item.name
              }}</span>
              <span v-else v-html="item.name" />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-medium">{{ $t("form.fightTime") }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="input.capture.fish.fight.hours"
            :label="$t('form.hours')"
            v-mask="'###'"
            min="0"
            max="59"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="input.capture.fish.fight.minutes"
            :label="$t('form.minutes')"
            v-mask="'##'"
            min="0"
            max="59"
            maxLength="7"
            outlined
            :dense="full"
            clearable
            type="number"
            :rules="timeRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-switch
            inset
            :label="$t('form.sharkEncounter')"
            v-model="input.sharkEncounter"
            hide-details
            v-if="input.sharkEncounter !== 'N/A'"
          ></v-switch>
          <h4 class="mt-2" v-else>{{ $t("form.sharkEncounter") }}: N/A</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <auto-complete
            :label="$t('form.condition')"
            itemType="conditions"
            v-model="input.capture.fish.condition"
            :isRecapture="isRecapture"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <auto-complete
            :label="$t('form.bait')"
            itemType="baits"
            v-model="input.capture.bait"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <auto-complete
            :label="$t('form.hook')"
            itemType="hooks"
            v-model="input.capture.hook.name"
          />
          <v-checkbox
            v-model="input.capture.hook.isRemoved"
            hide-details
            :label="$t('form.hookRemoved')"
            class="shrink mr-2 mt-0"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            auto-grow
            rows="1"
            :label="$t('form.notes')"
            v-model="input.capture.notes"
            outlined
            clearable
            dense
            counter
            :rules="notesRule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.gear')"
            itemType="gears"
            v-model="input.capture.gear"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.tournament')"
            itemType="tournaments"
            v-model="input.capture.tournament"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col :class="full ? 'text-end' : 'text-center'">
          <v-bottom-sheet
            v-model="sheet"
            persistent
            :fullscreen="!full"
            :scrollable="!full"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="reset"
                :x-large="!full"
              >
                {{ $t("form.addUser") }}
              </v-btn>
            </template>
            <new-user ref="newUser" @close="sheet = false" />
          </v-bottom-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.angler')"
            v-model="input.angler"
            @edit="editUser(input.angler)"
            addNew
            @add="sheet = true"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.captain')"
            v-model="input.captain"
            @edit="editUser(input.captain)"
            addNew
            @add="sheet = true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="0" sm="3" />
        <v-col cols="12" sm="6">
          <auto-complete
            :label="$t('form.boat')"
            itemType="boats"
            v-model="input.boat"
            @new="createBoat"
            add
          />
        </v-col>
        <v-col cols="0" sm="3" />
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.firstMate')"
            v-model="input.firstMate"
            @edit="editUser(input.firstMate)"
            addNew
            @add="sheet = true"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            :label="$t('form.secondMate')"
            v-model="input.secondMate"
            @edit="editUser(input.secondMate)"
            addNew
            @add="sheet = true"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AutoComplete from "../../components/appComponents/AutoComplete.vue";
import AutoCompleteUsers from "../../components/appComponents/AutoCompleteUsers.vue";
import {
  getUserInfo,
  setRequestedUserData,
  getRequestedUserData,
  DETERMINATIONS,
  LENGTH_TYPES,
  LENGTH_UNITS,
  WEIGHT_TYPES,
  WEIGHT_UNITS,
  cloneObject,
  getLengthLimitRules,
  getWeightLimitRules,
  validCoordinates,
} from "../../handlers";
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel,
} from "../models";
import NewBoat from "./NewBoat.vue";
import NewUser from "./NewUser.vue";
import FishingLocations from "../Dashboard/FishingLocations.vue";
import CoordinatesForm from "./CoordinatesForm.vue";
import DatePicker from "../../components/appComponents/DatePicker.vue";
import AutoCompleteLocation from "../../components/appComponents/AutoCompleteLocation.vue";
import ImageUploader from "../../components/appComponents/ImageUploader.vue";
import moment from "moment";
export default {
  name: "tag-form",
  components: {
    AutoCompleteUsers,
    AutoComplete,
    NewUser,
    NewBoat,
    FishingLocations,
    CoordinatesForm,
    DatePicker,
    AutoCompleteLocation,
    ImageUploader,
  },
  props: {
    type: String,
    setUser: {
      type: Boolean,
      default: false,
    },
    isRecapture: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    coordsStep() {
      return this.step;
    },
    specieSelected() {
      return !!this.input.capture.fish?.specie?.name;
    },
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    number: {
      get: function () {
        return this.input.number;
      },
      set: function (v) {
        this.input.number =
          !v || v === "BF" ? null : String(v).includes("BF") ? v : `BF${v}`;
      },
    },
    rule() {
      return [(v) => !!v || this.$t("form.required")];
    },
    ruleCoords() {
      return [
        (v) => !(v === null || v === undefined) || this.$t("form.required"),
      ];
    },
    timeRule() {
      return [(v) => (v <= 59 && v >= 0) || this.$t("form.timeRule")];
    },
    tagRule() {
      return [(v) => v?.length >= 8 || this.$t("form.tagRule")];
    },
    recaptureRule() {
      return [(v) => !v || v?.length >= 8 || this.$t("form.tagRule")];
    },
    notesRule() {
      return [
        (v) => !v || v?.length <= 300 || this.$t("form.maxLimit_300Rule"),
      ];
    },
    priorNumber: {
      get: function () {
        return this.input.priorTag.priorTagNumber;
      },
      set: function (v) {
        this.input.priorTag.priorTagNumber =
          !v || v === "BF" ? null : String(v).includes("BF") ? v : `BF${v}`;
        this.input.priorTag.havePriorTag =
          !!this.input.priorTag.priorTagNumber?.length;
      },
    },
    rulePriorTag() {
      return [
        () => this.input?.priorTag?.havePriorTag || this.$t("form.required"),
      ];
    },
    lengthRule() {
      const { specie, length } = this.input?.capture?.fish || {};
      const { value, unit } = length || {};
      const { acronym } = specie || {};
      const rule = getLengthLimitRules(acronym, value, unit);
      return rule;
    },
    weightRule() {
      const { specie, weight } = this.input?.capture?.fish || {};
      const { value, unit } = weight || {};
      const { acronym } = specie || {};
      const rule = getWeightLimitRules(acronym, value, unit);
      return rule;
    },
    tagNumberLabel() {
      return this.isRecapture
        ? this.$t("form.newNumber")
        : this.$t("form.number");
    },
    noRepeatNumberRule() {
      return [
        (v) => !v || v !== this.priorNumber || this.$t("form.noRepeatPriorTag"),
      ];
    },
  },
  data: () => ({
    input: cloneObject(initInput),
    onCreateBoat: false,
    sheet: false,
    loading: false,
    determinations: DETERMINATIONS,
    weight: {
      units: WEIGHT_UNITS,
      types: WEIGHT_TYPES,
    },
    length: {
      units: LENGTH_UNITS,
      types: LENGTH_TYPES,
    },
    showImgUploader: false,
    fishPicture: null,
    newRecord: false,
    invalidCoords: false,
    notify: false,
    message: false,
    bannerNote: true,
  }),
  methods: {
    ...mapActions("users", ["getUser"]),
    ...mapActions("entry", ["getFishPicture"]),
    async initRecord(record) {
      this.$refs.tagForm && (this.$refs.tagForm.scrollTop = 0);
      this.loading = true;
      this.resetAll();
      this.newRecord = !record;
      if (!record) {
        if (this.setUser) {
          const userInfo = getUserInfo();
          const { data } = (await this.getUser({ id: userInfo?.id })) || {};
          if (data && userInfo?.selectedRole) {
            this.input[userInfo.selectedRole] = getRequestedUserData(data);
          }
        }
      } else {
        this.input = { ...this.input, ...record };
        if (
          !("sharkEncounter" in this.input) ||
          this.input["sharkEncounter"] === null
        ) {
          if (moment(this.input.enteredDate).isBefore(moment("10/01/2022"))) {
            this.input["sharkEncounter"] = "N/A";
          }
        }
        const { _id, id } = record;
        await this.getPicture(_id || id);
      }
      this.loading = false;
    },
    validateCoordinates() {
      return this.$refs.coordsInputs && this.$refs.coordsInputs.validate();
    },
    submit() {
      const validCoords = validCoordinates(this.input.coordinates);
      let valid =
        this.$refs.tagForm && this.$refs.tagForm.validate() && validCoords;
      const data = setRequestedUserData(this.input);
      const payload = { valid, data };
      this.fishPicture && (payload.picture = this.fishPicture);
      return payload;
    },
    notifyInvalidCoords(isWater) {
      if (isWater) {
        this.invalidCoords = false;
        return;
      }
      this.invalidCoords = true;
      this.message = "Invalid coords, no seawater surface";
      this.notify = true;
    },
    createBoat() {
      this.$refs.newBoat && this.$refs.newBoat.init();
      this.onCreateBoat = true;
    },
    resetForBatch() {
      const input = cloneObject(initInput);
      input.createdDate = this.input.createdDate;
      input.capture.ocean = this.input.capture.ocean;
      input.capture.tournament = this.input.capture.tournament;
      input.boat = this.input.boat;
      input.captain = this.input.captain;
      input.firstMate = this.input.firstName;
      input.secondMate = this.input.secondMate;
      input.capture.fish.length.unit = this.input.capture.fish.length.unit;
      input.capture.fish.length.type = this.input.capture.fish.length.type;
      input.capture.fish.length.determination =
        this.input.capture.fish.length.determination;
      input.capture.fish.weight.unit = this.input.capture.fish.weight.unit;
      input.capture.fish.weight.type = this.input.capture.fish.weight.type;
      input.capture.fish.weight.determination =
        this.input.capture.fish.weight.determination;
      this.input = input;
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    resetAll() {
      this.input = cloneObject(initInput);
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    reset() {
      this.$refs.newUser && this.$refs.newUser.init();
    },
    resetInfo() {
      this.input.number = null;
      const { capture, sharkEncounter } = cloneObject(initInput);
      const { fish: captureFish, notes } = capture;
      this.input.capture.fish.fight = captureFish.fight;
      this.input.capture.fish.length.value = captureFish.length.value;
      this.input.capture.fish.weight.value = captureFish.weight.value;
      this.input.capture.fish.condition = captureFish.condition;
      this.input.capture.fish.specie = captureFish.specie;
      this.input.capture.notes = notes;
      this.input.sharkEncounter = sharkEncounter;
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    editUser(user) {
      if (user?.id) {
        this.sheet = true;
        setTimeout(() => {
          this.$refs.newUser && this.$refs.newUser.initUser(user.id);
        }, 50);
      }
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }

      if (this.$refs.fishingLocations) {
        this.$refs.fishingLocations.getDescription(coordinates);
      }

      this.input.coordinates = { ...coordinates };
    },
    setBoatName(name) {
      if (name) {
        if (this.input?.boatName && !this.input?.boat?.name) {
          this.input.boat.name = this.input.boatName;
        }

        if (this.input?.boat?.name) {
          this.input.boatName = this.input.boat.name;
        }
      }
    },
    setMarker(coordinates = null) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }
      this.input.coordinates = { ...this.input.coordinates, ...coordinates };
    },
    async getPicture(id) {
      if (!id) return;
      const response = await this.getFishPicture({ id });
      this.fishPicture =
        response?.data?.docs && JSON.parse(response.data.docs)?.image;
    },
    initMap(value) {
      setTimeout(async () => {
        if (value) {
          this.$refs.coordsInputs &&
            this.$refs.coordsInputs.updateForm(this.input.coordinates);
          this.$refs.fishingLocations &&
            this.$refs.fishingLocations.handleData(this.input.coordinates);
        } else {
          this.$refs.locations &&
            (await this.$refs.locations.getLocationByName());
        }
      }, 50);
    },
  },
  watch: {
    "input.boatName": {
      handler: "setBoatName",
    },
    "input.boat.name": {
      handler: "setBoatName",
    },
    step: {
      handler: "initMap",
    },
  },
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null },
};
</script>

<style>
.v-input.v-input--switch--inset {
  margin-top: 4px;
}

.v-input.v-input--switch--inset .v-input--switch__track::after {
  content: "No";
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 5px;
  margin-top: 2px;
}

.v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty
  .v-input--switch__track::after {
  content: "Yes";
  font-size: 8px;
  /* font-weight: bold; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 6px;
  margin-top: 3px;
  color: white;
}

.banner-note div[class~="v-banner__wrapper"] {
  padding: 0 1em !important;
  border-bottom: 0 !important;
  margin-bottom: 10px;
}

.banner-note div[class~="v-banner__content"] {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 24px;
}

.banner-note div[class~="v-banner__icon"] {
  margin: 0 !important;
}
</style>
