const userData = {
  id: null,
  legacyId: null,
  firstName: null,
  lastName: null,
  middleName: null,
  email: null,
  memberId: null,
  memberExpiration: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  country: null,
};
class CrewViewModel {
  constructor() {
    this.angler = userData;
    this.captain = userData;
    this.firstMate = userData;
    this.secondMate = userData;
    this.boat = {
      id: null,
      name: null,
    };
  }
}

class ReleaseCaptureViewModel {
  constructor() {
    this.fish = {
      species: null,
      quantity: 0,
    };

    this.capture = {
      ocean: null,
      notes: null,
      tournament: null,
      gear: null,
      bait: null,
      releases: [],
    };
  }
}

const date = new Date();
date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

class CaptureViewModel {
  constructor() {
    this.number = null;
    this.sharkEncounter = null;
    this.enteredDate = date.toISOString();
    this.createdDate = date.toISOString();
    this.priorTag = {
      havePriorTag: false,
      priorTagNumber: null,
    };
    this.relLines = null;
    this.relLines0 = null;
    this.relNumLi = null;
    this.capture = {
      ocean: null,
      notes: null,
      tournament: {
        id: null,
        name: null,
      },
      bait: {
        id: null,
        name: null,
      },
      gear: {
        id: null,
        name: "ROD AND REEL",
      },
      hook: {
        isRemoved: false,
        name: null,
      },
      fish: { ...new FishTagModel() },
    };
  }
}

class FishTagModel {
  constructor() {
    this.specie = {
      id: null,
      name: null,
      isNonBillFish: null,
    };
    this.condition = {
      id: null,
      name: null,
    };
    this.length = {
      value: null,
      unit: null,
      determination: null,
      type: null,
    };
    this.weight = {
      value: null,
      unit: null,
      determination: null,
      type: null,
    };
    this.fight = {
      hours: null,
      minutes: null,
    };
  }
}

class FishReleaseModel {
  constructor(id = null, acronym = null, name = null, qty = null) {
    this.id = id;
    this.acronym = acronym;
    this.name = name;
    this.qty = qty;
  }
}

class CoordinatesViewModel {
  constructor() {
    this.coordinates = {
      description: null,
      longitude: {
        degrees: null,
        minutes: null,
        hemisphere: null,
      },
      latitude: {
        degrees: null,
        minutes: null,
        hemisphere: null,
      },
    };
  }
}

class TournamentsViewModel {
  constructor() {
    this._id = null;
    this.legacyId = null;
    this.name = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).forEach(
      (item) => data[item] && (this[item] = data[item])
    );

    return { ...this };
  }
}

class BoatsViewModel {
  constructor() {
    this._id = null;
    this.legacyId = null;
    this.owner = {
      id: null,
      firstName: null,
      lastName: null,
    };
    this.name = null;
    this.make = null;
    this.model = null;
    this.length = null;
    this.port = null;
    this.enteredBy = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).forEach((item) => {
      if (item === "owner") {
        data?.owner?.id && (this.owner.id = data.owner.id);
        data?.owner?.firstName && (this.owner.firstName = data.owner.firstName);
        data?.owner?.lastName && (this.owner.lastName = data.owner.lastName);
      } else {
        data[item] && (this[item] = data[item]);
      }
    });

    return { ...this };
  }
}

class SpeciesViewModel {
  constructor() {
    this._id = null;
    this.acronym = null;
    this.name = null;
    this.nonBillFish = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class BaitsViewModel {
  constructor() {
    this._id = null;
    this.acronym = null;
    this.name = null;
    this.legacyId = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class GearsViewModel {
  constructor() {
    this._id = null;
    this.acronym = null;
    this.name = null;
    this.legacyId = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class TrophiesViewModel {
  constructor() {
    this._id = null;
    this.name = null;
    this.fisherName = null;
    this.fisherId = null;
    this.role = null;
    this.taggedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

export {
  CrewViewModel,
  CaptureViewModel,
  CoordinatesViewModel,
  ReleaseCaptureViewModel,
  TournamentsViewModel,
  BoatsViewModel,
  SpeciesViewModel,
  BaitsViewModel,
  GearsViewModel,
  TrophiesViewModel,
  FishTagModel,
  FishReleaseModel,
};
