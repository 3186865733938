import { handleCall } from "@/handlers/index";
class UserModule {
  constructor(httpClientFactory) {
    const userAPI = httpClientFactory.clients.entries;

    this._store = {
      state: {
        data: {
          species: null,
        },
      },
      getters: {},
      mutations: {},
      actions: {
        getUser({ state }, params) {
          const path = `/users/${params.id}`;
          return handleCall("GET", userAPI, path);
        },
        getUsers({ state }, params) {
          let queryParams = `page=${params.page}&limit=${params.limit}`;
          params?.name &&
            (queryParams = queryParams.concat(`&name=${params.name}`));
          const path = `/users?${queryParams}`;
          return handleCall("GET", userAPI, path);
        },
        getUsersByName({ state }, params) {
          const queryParams = `name=${params.name}&status=${
            params.status || "notapplicable"
          }&page=${params.page}&limit=${params.limit}`;
          const path = `/users?${queryParams}`;
          return handleCall("GET", userAPI, path);
        },
        getMembersByStatus({ state }, params) {
          const queryParams = `page=${params.page}&limit=${
            params.limit
          }&status=${params.status || "notapplicable"}`;
          const path = `/users?${queryParams}`;
          return handleCall("GET", userAPI, path);
        },
        saveUserInfo({ state }, params) {
          const { _id, id } = params;
          const path = `/users/${_id || id}`;
          return handleCall("PATCH", userAPI, path, params);
        },
        login({ state }, data) {
          return handleCall("POST", userAPI, "login", data);
        },
        signup({ state }, params) {
          return handleCall("POST", userAPI, "register", params);
        },
        forgotPwd({ state }, data) {
          return handleCall("POST", userAPI, "forgot-password", data);
        },
        resetPwd({ state }, data) {
          return handleCall("POST", userAPI, "reset-password", data);
        },
        activationEmail({ state }, data) {
          return handleCall("POST", userAPI, "activation-email", data);
        },
        accountActivation({ state }, data) {
          return handleCall("POST", userAPI, "account-activation", data);
        },
        isAccountActivated({ state }, params) {
          const path = `/account-activated?email=${params.email}`;
          return handleCall("GET", userAPI, path);
        },
        facebookLogin({ state }, data) {
          return handleCall("POST", userAPI, "facebook", data);
        },
        addUser({ state }, params) {
          const path = `/users/`;
          return handleCall("POST", userAPI, path, params);
        },
        checkEmail({ state }, params) {
          const path = `/users?email=${params.email}`;
          return handleCall("GET", userAPI, path);
        },
        verifyCaptcha({ state }, { token }) {
          const path = `/captcha?token=${token}`;
          return handleCall("GET", userAPI, path);
        },
        checkPersonalPhone({ state }, params) {
          const path = `/users?personalPhone=${params.personalPhone}`;
          return handleCall("GET", userAPI, path);
        },
      },
      namespaced: true,
    };
  }

  get store() {
    return this._store;
  }
}

export default UserModule;
