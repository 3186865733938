import router from "@/router/index";
import moment from "moment";
import { i18n } from "../main";

export const handleCall = async (method, api, path, params = null) => {
  try {
    let headers = {};
    const openRouteKeys = [
      "login",
      "password",
      "register",
      "captcha",
      "token",
      "statistics",
      "activation-email",
      "account-activation",
      "account-activated",
    ];
    const isOpenRoute = openRouteKeys.some((routeKey) =>
      String(path || "").includes(routeKey)
    );

    if (!isOpenRoute) {
      const token = loggedIn();
      headers = { ...headers, Authorization: token ? `Bearer ${token}` : null };
    }

    if (String(path || "").includes("images")) {
      headers = { ...headers, "Content-Type": "image/jpg" };
    }

    let response = null;
    if (!String(path).includes("undefined")) {
      switch (method) {
        case "POST":
          response = await api.post(path, params, { headers });
          break;

        case "PATCH":
          response = await api.patch(path, params, { headers });
          break;

        case "DELETE":
          response = await api.delete(path, { headers });
          break;

        case "GET":
          response = await api.get(path, { headers });
          break;

        default:
          break;
      }
    }
    return response;
  } catch (e) {
    if (
      String(e).includes("404") ||
      String(e).includes("409") ||
      String(e).includes("500")
    ) {
      return e.response;
    }

    if (String(e).includes("401")) {
      localStorage.removeItem("data");
      router.push({ path: "login" }).catch(() => {});
    }
    return null;
  }
};

export const handleCoords = (coords) => {
  const { latitude: lat, longitude: lng } = coords;

  const latitude =
    (Number(lat.degrees) + Number(lat.minutes) / 60) *
    (lat.hemisphere === "S" ? -1 : 1);
  const longitude =
    (Number(lng.degrees) + Number(lng.minutes) / 60) *
    (lng.hemisphere === "W" ? -1 : 1);

  return [latitude, longitude];
};

export const reverseCoords = (coords) => {
  const { lat, lng } = coords;
  const latitude = handleLimits(lat, 90, ["N", "S"]);
  const longitude = handleLimits(lng, 180, ["E", "W"]);
  return { latitude, longitude };
};

const handleLimits = (value, limit, hemispheres) => {
  const integer = Math.trunc(value);
  const rate = integer / limit;
  const co_terminal = integer - limit * Math.trunc(rate);
  const degree = Math.abs(rate) >= 1 ? co_terminal : integer;
  const option = Math.abs(rate) >= 1 && Math.trunc(rate) % 2;
  return {
    degrees: !option ? Math.abs(degree) : 180 - Math.abs(degree),
    minutes: Math.abs(value - integer) * 60,
    hemisphere: getHemisphere(value, rate, hemispheres),
  };
};

const getHemisphere = (value, rate, hemispheres) => {
  const ref = value >= 0 ? 0 : 1;
  const option = Math.abs(rate) >= 1 && Math.trunc(rate) % 2;
  return !option ? hemispheres[ref] : hemispheres[ref ? 0 : 1];
};

export const coordsPatterns = {
  lat: {
    isDMS:
      /^(([1-8]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|90\D+0\D+0)\D+[NSns]$/,
    isDDM: /^(([1-8]?[0-9])\D+[1-6]?[0-9](\.\d{1,3})?|90(\D+0)?)\D+([NSns])$/,
    isDD: /^[\+-]?(([1-8]?[0-9])(\.\d{1,6})?|90)\D*[NSns]?$/,
  },
  lng: {
    isDMS:
      /^((1[0-7][0-9]|[1-9]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|180\D+0\D+0)\D+[EWew]$/,
    isDDM:
      /^((1[0-7][0-9]|[1-9]?[0-9])\D+[1-6]?[0-9](\.\d{1,3})?|180(\D+0)?)\D+([EWew])$/,
    isDD: /^[\+-]?((1[0-7][0-9]|[1-9]?[0-9])(\.\d{1,6})?|180)\D*[EWew]?$/,
  },
};

export const formatStr = (str) => {
  return str === "NULL" || str === "UNKNOWN" ? "" : str;
};

export const formatDate = (value) => {
  const date = moment(value || null);
  return date.isValid() ? date.format("MM/DD/YYYY") : "";
};

export const getAddress = (user) => {
  const { line1, city, zip, state, country } = user?.address || {};
  let address = line1 || "";
  !!address.length && city && (address = address.concat(", "));
  city && (address = address.concat(city));
  !!address.length && zip && (address = address.concat(", "));
  zip && (address = address.concat(zip));
  !!address.length && state && (address = address.concat(", "));
  state && (address = address.concat(state));
  !!address.length && country && (address = address.concat(", "));
  country && (address = address.concat(country));
  !address.length && (address = "No address registered");
  return address;
};

export const setOrder = (from = null, to = null) => {
  const order = localStorage.getItem("order");
  if (order) {
    const parsed = JSON.parse(order);
    if (from !== null && to !== null && to < parsed.length && to >= 0) {
      parsed.splice(to, 0, parsed.splice(from, 1)[0]);
      localStorage.setItem("order", JSON.stringify(parsed));
    }
    return parsed;
  } else {
    const data = JSON.stringify([
      "location",
      "history",
      "trophy",
      "competition",
    ]);
    localStorage.setItem("order", data);
    return data;
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const loggedIn = () => {
  const token = JSON.parse(localStorage.getItem("data"))?.token;
  return token;
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem("data"))?.user;
};

export const getTutorialInfo = () => {
  return JSON.parse(localStorage.getItem("tutorial"));
};

export const defaultAvatar = require("@/assets/billfish-logo-new.png");
export const defaultLogo = require("@/assets/billfish-logo.png");
export const billfishDefaultImg = require("@/assets/billfish-default.png");

export const fixTimezone = (value) => {
  if (!value) {
    return null;
  }
  const date = new Date(value);
  const tzOffset = date.getTimezoneOffset();
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return null;
  }
  return momentDate.add(tzOffset, "minutes");
};

// export const fixTimezone = (date) => {
//   const ISO_FORMAT_REGEX =
//     /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
//   if (ISO_FORMAT_REGEX.test(date)) {
//     return String(date).split("T")[0];
//   }
//   return date;
// };

export const setRequestedUserData = (input) => {
  const data = cloneObject(input);
  const { angler, captain, firstMate, secondMate } = data || {};
  return {
    ...data,
    angler: getRequestedUserData(angler),
    captain: getRequestedUserData(captain),
    firstMate: getRequestedUserData(firstMate),
    secondMate: getRequestedUserData(secondMate),
  };
};

export const getRequestedUserData = (user) => {
  return {
    id: user?._id || user?.id || null,
    legacyId: user?.legacyId || null,
    firstName: user?.firstName || null,
    lastName: user?.lastName || null,
    middleName: user?.middleName || null,
    email: user?.email || null,
    memberId: user?.memberId || null,
    memberExpiration:
      user?.memberExpiration || user?.membership?.expirationDate || null,
    address: user?.address?.line1 || null,
    city: user?.address?.city || null,
    state: user?.address?.state || null,
    zip: user?.address?.zip || null,
    country: user?.address?.country || null,
  };
};

export const sendRecaptureMail = async () => {
  const subject = i18n.t("recaptureMail.subject");
  const body = i18n.t("recaptureMail.body");
  const hrefURL = `
  mailto:tag@billfish.org?subject=${subject}
  &body=${encodeURIComponent(body)}
  `;
  const a = document.createElement("a");
  a.href = hrefURL;
  document.body.appendChild(a);
  a.click();
};

export const DETERMINATIONS = [
  { name: "ESTIMATED", code: "estimated" },
  { name: "MEASURED", code: "measured" },
  { name: "UNKNOWN", code: "unknown" },
];

export const WEIGHT_UNITS = [
  { name: "KILOGRAMS (KG)", code: "kg" },
  { name: "POUNDS (LB)", code: "lb" },
  { name: "UNKNOWN", code: "unknown" },
];

export const WEIGHT_TYPES = [
  { name: "DRESSED", code: "dressed" },
  { name: "ESTIMATED", code: "estimated" },
  { name: "ROUND", code: "round" },
  { name: "WHOLE", code: "whole" },
  { name: "UNKNOWN", code: "unknown" },
];

export const LENGTH_UNITS = [
  { name: "INCHES (IN)", code: "inches" },
  { name: "FEET (FT)", code: "feet" },
  { name: "CENTIMETERS (CM)", code: "centimeters" },
  { name: "METERS (M)", code: "meters" },
  { name: "UNKNOWN", code: "unknown" },
];

export const LENGTH_TYPES = [
  { name: "ESTIMATED", code: "estimated" },
  { name: "EYEFORK", code: "eyefork" },
  { name: "FORK LENGTH", code: "forkLength" },
  { name: "LOWER JAW FORK LENGTH", code: "jawfork" },
  { name: "TOTAL LENGTH", code: "total" },
  { name: "UNKNOWN", code: "unknown" },
];

export const LATITUDE_HEMISPHERE = ["N", "S"];

export const LONGITUDE_HEMISPHERE = ["E", "W"];

export const GENDER_OPTIONS = [
  { name: "Male", code: "male" },
  { name: "Female", code: "female" },
  { name: "NA", code: "na" },
];

export const cloneObject = (object) => {
  let clone = {};
  try {
    clone = window.structuredClone(object);
  } catch (error) {
    console.log(
      "structuredClone method can't be used in this env for deep cloning, JSON methods need to be used"
    );
    clone = JSON.parse(JSON.stringify(object));
  }
  return clone;
};

export const getFile = (base64 = "", type = "excel", title = "", name = "") => {
  if (!base64) {
    return;
  }

  const str = title?.length >= 20 ? title.split(" ")[0] : title;
  let filename = name || `${str} ${moment().format("MM.DD.YYYY")}`;

  const a = document.createElement("a");
  let headerFile;
  if (type === "csv") {
    headerFile = "data:attachment/csv";
    filename = filename.concat(".csv");
  } else if (type === "excel") {
    headerFile = "data:application/vnd.ms-excel";
    filename = filename.concat(".xlsx");
  }
  a.href = `${headerFile};base64, ${base64}`;
  a.download = filename;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const NOT_SPECIFIED = "NOT SPECIFIED";

export const getEventType = (type) => {
  switch (type) {
    case "R":
      return "RELEASE";

    case "C":
      return "RECAPTURE";

    case "T":
    default:
      return "TAG";
  }
};

export const getImg = (url = "", title = "") => {
  if (!url?.length) {
    return;
  }

  const filename = `${title}.jpg`;
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const LAYERS = [
  "Streets",
  "Topographic",
  "NationalGeographic",
  "Oceans",
  "Gray",
  "DarkGray",
  "Imagery",
  "ImageryClarity",
  "ImageryFirefly",
  "ShadedRelief",
  "Terrain",
  "Physical",
];

export const WATER_SURFACES = [
  "Bay",
  "Canal",
  "Channel",
  "Cove",
  "Delta",
  "Estuary",
  "Fjord",
  "Gulf",
  "Lagoon",
  "Ocean",
  "Reef",
  "Sea",
  "Strait",
];

const WEIGHT_UNIT = {
  KG: "KILOGRAMS (KG)",
  LBS: "POUNDS (LB)",
  UNKNOWN: "UNKNOWN",
};

const LENGTH_UNIT = {
  IN: "INCHES (IN)",
  FEET: "FEET (FT)",
  CM: "CENTIMETERS (CM)",
  M: "METERS (M)",
  UNKNOWN: "UNKNOWN",
};

const FISH_MAX_FEET_LENGTH = {
  BK: 15.5,
  BM: 12,
  SF: 11.5,
  SP: 8,
  ST: 12,
  WM: 0,
  SW: 15,
  BLT: 15,
  YFT: 8,
  ALB: 5,
};

const FISH_MAX_LBS_WEIGHT = {
  BK: 1700,
  BM: 2000,
  SF: 230,
  SP: 120,
  ST: 500,
  WM: 0,
  SW: 1200,
  BLT: 2000,
  YFT: 450,
  ALB: 150,
};

const meters2feet = (meters) => meters * 3.28084;
const inches2feet = (inches) => inches / 12;
const cm2feet = (cm) => (cm / 100) * 3.28084;
const kg2lbs = (kg) => kg / 0.45359237;

const feet2meters = (feet) => Math.floor(feet / 3.28084);
const feet2inches = (feet) => Math.floor(feet * 12);
const feet2cm = (feet) => Math.floor((feet / 3.28084) * 100);
const lbs2kg = (lbs) => Math.floor(lbs * 0.45359237);

export const getLengthLimitRules = (fish, value, unit) => {
  const maxLength = FISH_MAX_FEET_LENGTH[fish] || 0;

  if (!maxLength || !value) {
    return [];
  }

  switch (unit) {
    case LENGTH_UNIT.IN: {
      const converted = inches2feet(value);
      const convertedMaxLength = feet2inches(maxLength);
      return [
        (v) =>
          !v || converted < maxLength || `Max: ${convertedMaxLength} ${unit}`,
      ];
    }

    case LENGTH_UNIT.FEET: {
      return [(v) => !v || value < maxLength || `Max: ${maxLength} ${unit}`];
    }

    case LENGTH_UNIT.M: {
      const converted = meters2feet(value);
      const convertedMaxLength = feet2meters(maxLength);
      return [
        (v) =>
          !v || converted < maxLength || `Max: ${convertedMaxLength} ${unit}`,
      ];
    }

    case LENGTH_UNIT.CM: {
      const converted = cm2feet(value);
      const convertedMaxLength = feet2cm(maxLength);
      return [
        (v) =>
          !v || converted < maxLength || `Max: ${convertedMaxLength} ${unit}`,
      ];
    }

    case LENGTH_UNIT.UNKNOWN:
    default:
      return [];
  }
};

export const getWeightLimitRules = (fish, value, unit) => {
  const maxWeight = FISH_MAX_LBS_WEIGHT[fish] || 0;

  if (!maxWeight || !value) {
    return [];
  }

  if (WEIGHT_UNIT.KG === unit) {
    const converted = kg2lbs(value);
    const convertedMaxWeight = lbs2kg(maxWeight);
    return [
      (v) =>
        !v || converted < maxWeight || `Max: ${convertedMaxWeight} ${unit}`,
    ];
  }

  if (WEIGHT_UNIT.LBS === unit) {
    return [(v) => !v || value < maxWeight || `Max: ${maxWeight} ${unit}`];
  }

  return [];
};

export const validCoordinates = (coordinates) => {
  if (!coordinates) {
    return false;
  }

  const { latitude, longitude } = coordinates;

  if (!latitude || !longitude) {
    return false;
  }

  const noLat = Object.values(latitude).some((v) => v === null);
  const noLng = Object.values(longitude).some((v) => v === null);

  if (noLat || noLng) {
    return false;
  }

  return true;
};
