import { loggedIn } from "../handlers/index";

export default [
  {
    name: "Dashboard Layout",
    path: "*",
    redirect: "dashboard",
    component: () => import("@/layouts/full-layout/Layout.vue"),
    children: [
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
      },
      {
        name: "Search",
        path: "search",
        component: () => import("@/views/Search/Search.vue"),
      },
    ],
    beforeEnter(to, from, next) {
      const logged = loggedIn();
      const { id, record } = to?.query || {};

      if (!logged) {
        if (id && record) {
          localStorage.setItem("recoverRecord", JSON.stringify({ id, record }));
        }
        next({ path: "login" });
        return;
      }

      if (!id && !record) {
        const recoverRecord = localStorage.getItem("recoverRecord");
        if (recoverRecord) {
          localStorage.removeItem("recoverRecord");
          next({ path: "dashboard", query: JSON.parse(recoverRecord) });
          return;
        }
      }

      next();
    },
  },
  {
    path: "",
    redirect: "/login",
    component: () => import("@/views/Auth/AuthLayout.vue"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/views/Auth/LogIn.vue"),
      },
      {
        name: "forgot",
        path: "/forgot-password",
        component: () => import("@/views/Auth/ForgotPassword.vue"),
      },
      {
        name: "reset",
        path: "/reset-password",
        component: () => import("@/views/Auth/ResetPassword.vue"),
        beforeEnter(to, from, next) {
          const { token, email } = to?.query;
          const localReset = JSON.parse(localStorage.getItem("tempReset"));
          !localReset && (!token || !email) ? next({ path: "login" }) : next();
        },
      },
      {
        name: "signup",
        path: "/signup",
        component: () => import("@/views/Auth/SignUp.vue"),
      },
      {
        name: "privacy",
        path: "/privacy",
        component: () => import("@/views/Auth/Privacy.vue"),
      },
      {
        name: "activation",
        path: "/activation-email",
        component: () => import("@/views/Auth/ActivationEmail.vue"),
      },
      {
        name: "activated",
        path: "/account-activated",
        component: () => import("@/views/Auth/AccountActivated.vue"),
        beforeEnter(to, from, next) {
          const { code, email } = to?.query;
          !code || !email ? next({ path: "login" }) : next();
        },
      },
    ],
    beforeEnter(to, from, next) {
      if (to?.name !== "login") {
        next();
        return;
      }

      const token = loggedIn();
      token ? next({ path: "/searches" }) : next();
    },
  },
];
